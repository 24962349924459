import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import RequestCard from "../../components/requestCard";
import closeIcon from "../../assets/images/close.svg";
import search from "../../assets/images/search.svg";
import { ticketReqUsers } from "../../data";

export const Ticketrequests = () => {
  const [selected, setSelected] = useState(null);
  const navigate = useNavigate();

  return (
    <div className="w-full h-screen flex justify-center ">
      <div className="w-[1194px] max-h-[988px] bg-white mt-11 mb-11 p-5 overflow-scroll">
        <h1 className="text-2xl	font-medium">Ticket requests</h1>

        <div className="flex flex-col gap-5 mt-2">
          {ticketReqUsers.map((data) => (
            <RequestCard data={data} onClick={() => setSelected(data)} />
          ))}
        </div>

        <div className="flex justify-end m-5 gap-3">
          <button className="bg-[#BCBCBC] rounded-full text-white w-[i43px]  py-2 px-4 flex items-center">
            <img className="mr-1" src={closeIcon} />
            Cancel
          </button>
          <button
            className="bg-[#CD4373] text-white rounded-full w-[189px] py-2 px-4 flex items-center place-content-center"
            onClick={() =>
              selected &&
              navigate("/user/ticket-list", { state: { user: selected } })
            }
          >
            <img className="mr-2" src={search} />
            Search Flights
          </button>
        </div>
      </div>
    </div>
  );
};
