import React from "react";

const TripInfo = ({ from, to, dateTime }) => {
    return (
        <>
            <div className="flex justify-between">
                <div>
                    <p className="text-sm text-gray-500">From</p>
                    <p className="text-base font-medium">{from}</p>
                </div>
                <div>
                    <p className="text-sm text-gray-500">To</p>
                    <p className="text-base font-medium">{to}</p>
                </div>
                <div>
                    <p className="text-sm text-gray-500">Date and Time</p>
                    <p className="text-base font-medium">{dateTime}</p>
                </div>
            </div>
        </>
    );
};

export default TripInfo;
