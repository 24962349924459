import React from "react";
import { toast } from "react-toastify";

import Calendar from "./calendar";
import FilterOptionList from "./filterOption";
import ListItem from "./listItem";
import closeIcon from "../../assets/images/close.svg";
import sendIcon from "../../assets/images/sendIcon.svg";
import { IoMdArrowDown } from "react-icons/io";
import UserDetails from "./userDetails";
import { tickets } from "../../data";
import { useLocation } from "react-router-dom";

function TicketList() {
  // const navigate = useNavigate();
  const { state } = useLocation();
  console.log(state);
  return (
    <>
      <UserDetails data={state?.user || {}} />
      <div className="w-[100%] flex">
        <div class=" w-[30%] bg-white border rounded-[5px] shadow-sm mr-4">
          <FilterOptionList />
        </div>
        <div class=" w-[70%] flex flex-col">
          <div className="w-[100%]">
            <Calendar />
          </div>
          <div className="w-[100%]">
            <table className="w-full text-sm text-left mb-2">
              <thead className="font-medium text-slate-700 rounded-md uppercase">
                {/* <tr className=""> */}
                <th></th>
                <th>AIRLINE</th>
                <th>
                  <th className="w-[96px]">DEPARTURE</th>
                  <th className="w-[140px] pl-[20px]">DURATION</th>
                  <th>ARRIVAL</th>
                </th>

                {/* <th>AGENT</th> */}

                <th>Best Price</th>
                <th className="flex items-center text-[red]">
                  PRICE <IoMdArrowDown className="h-[16px] w-[16px]" />
                </th>
                {/* </tr> */}
              </thead>
              <td className="p-1 m-0"></td>
              <tbody className="rounded-md bg-[#f5f6f8]">
                {tickets.map((item, i) => (
                  <ListItem index={i} data={item} />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="flex justify-end m-5 gap-3">
        <button className="bg-[#BCBCBC] rounded-full text-white text-[16px]  py-2 px-4 flex items-center">
          <img className="mr-1" src={closeIcon} />
          Cancel
        </button>
        <button
          className="bg-[#CD4373] text-white rounded-full text-[16px] py-2 px-6 flex items-center place-content-center"
          onClick={() => {
            toast.success("The travel plan has been successfully sent");
          }}
        >
          <img className="mr-2" src={sendIcon} />
          Send Plan
        </button>
      </div>
    </>
  );
}

export default TicketList;
