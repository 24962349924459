import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import NavBar from "./components/navbar";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <div className="container-fluid flex flex-col bg-[#f5f6f8]">
      <NavBar />
      <div className="max-w-screen-xl flex flex-col w-[100%] justify-between mx-auto ">
        <Outlet />
      </div>
      <ToastContainer
        closeOnClick
        style={{ width: "auto", minWidth: "340px", maxWidth: "450px" }}
        position={"top-right"}
        bodyStyle={{ color: "#756f86" }}
      />
    </div>
  );
}

export default App;
