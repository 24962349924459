import React from "react";
import eye from "../../assets/images/eye.svg";
import TripInfo from "./tripInfo";

const RequestCard = ({ data, onClick }) => {
  return (
    <div
      className="w-full max-h-[197px] border shadow rounded-lg flex p-3 gap-28"
      onClick={onClick}
    >
      <div className="w-[30%] flex gap-8">
        <input
          type="checkbox"
          id="checkbox1"
          className="peer relative h-6 w-6 shrink-0 appearance-none rounded-sm border after:absolute after:left-0 after:top-0 after:h-full after:w-full after:bg-[url('data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9JzMwMHB4JyB3aWR0aD0nMzAwcHgnICBmaWxsPSIjZmZmZmZmIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgMTAwIDEwMCIgdmVyc2lvbj0iMS4xIiB4PSIwcHgiIHk9IjBweCI+PHRpdGxlPmljb25fYnlfUG9zaGx5YWtvdjEwPC90aXRsZT48ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz48ZyBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48ZyBmaWxsPSIjZmZmZmZmIj48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyNi4wMDAwMDAsIDI2LjAwMDAwMCkiPjxwYXRoIGQ9Ik0xNy45OTk5ODc4LDMyLjQgTDEwLjk5OTk4NzgsMjUuNCBDMTAuMjI2Nzg5MSwyNC42MjY4MDE0IDguOTczMTg2NDQsMjQuNjI2ODAxNCA4LjE5OTk4Nzc5LDI1LjQgTDguMTk5OTg3NzksMjUuNCBDNy40MjY3ODkxNCwyNi4xNzMxOTg2IDcuNDI2Nzg5MTQsMjcuNDI2ODAxNCA4LjE5OTk4Nzc5LDI4LjIgTDE2LjU4NTc3NDIsMzYuNTg1Nzg2NCBDMTcuMzY2ODIyOCwzNy4zNjY4MzUgMTguNjMzMTUyOCwzNy4zNjY4MzUgMTkuNDE0MjAxNCwzNi41ODU3ODY0IEw0MC41OTk5ODc4LDE1LjQgQzQxLjM3MzE4NjQsMTQuNjI2ODAxNCA0MS4zNzMxODY0LDEzLjM3MzE5ODYgNDAuNTk5OTg3OCwxMi42IEw0MC41OTk5ODc4LDEyLjYgQzM5LjgyNjc4OTEsMTEuODI2ODAxNCAzOC41NzMxODY0LDExLjgyNjgwMTQgMzcuNzk5OTg3OCwxMi42IEwxNy45OTk5ODc4LDMyLjQgWiI+PC9wYXRoPjwvZz48L2c+PC9nPjwvc3ZnPg==')] after:bg-[length:40px] after:bg-center after:bg-no-repeat after:content-[''] checked:bg-[#CD4373] hover:ring hover:ring-gray-300 focus:outline-none"
        />
        <div className="flex flex-col gap-2">
          <p className="text-sm text-gray-500">Travellers</p>
          <div className="flex gap-3">
            <img src={data?.image} alt="" className="w-6 h-6 rounded-full" />
            <p>{data?.name}</p>
            <img src={eye} alt="" />
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-8 w-full">
        {data?.tickets?.map((it, ind) => (
          <>
            {ind !== 0 && (
              <div className="flex items-center gap-2">
                <p className="italic">Return</p>
                <div className="w-full h-[1px] bg-[#D8DCD2]"></div>
              </div>
            )}
            <TripInfo from={it?.from} to={it?.to} dateTime={it?.dateAndTime} />
          </>
        ))}
      </div>
    </div>
  );
};

export default RequestCard;
