import React from "react";
import RequestCard from "../../components/requestCard";
import qatarAirwaysPic from "../../assets/images/home-pic-5.png";
import closeIcon from "../../assets/images/close.svg";
import sendIcon from "../../assets/images/sendIcon.svg";
import downArrow from "../../assets/images/down-arrow-icon.svg";
import { ticketReqUsers, tickets } from "../../data";
import ListItem from "./listItem";
import { toast } from "react-toastify";
const Quotation = () => {
  return (
    <div className="w-full flex justify-center">
      <div className="w-[1194px] bg-white mt-11 mb-11 p-5 overflow-scroll">
        <h1 className="text-2xl	font-medium">Ticket requests</h1>
        <div className="flex flex-col gap-5 mt-2">
          {ticketReqUsers.slice(0, 1).map((data) => (
            <RequestCard data={data} />
          ))}
        </div>
        <div>
          <div className="border-dotted border-b-2 border-[#878787] mt-4 mb-5"></div>
          <h1 className="text-2xl	font-medium">Quotation from Agents</h1>
          <div className="mt-3">
            <table className="w-full text-sm text-left">
              <thead className="h-[50px] bg-[#EFECEC] font-medium text-slate-700 rounded-md uppercase">
                {/* <tr className=""> */}
                <th></th>
                <th>AIRLINE</th>
                <th>
                  <th className="w-[96px]">DEPARTURE</th>
                  <th className="w-[140px] pl-[20px]">DURATION</th>
                  <th>ARRIVAL</th>
                </th>
                <th>AGENT</th>
                <th className="flex text-[#CD4373]">
                  PRICE
                  <img src={downArrow} alt="" />
                </th>
                {/* </tr> */}
              </thead>
              <tbody className="bg-white">
                {tickets.slice(0, 3).map((it) => (
                  <ListItem data={it} />
                ))}
              </tbody>
            </table>
            <div className="flex justify-end m-5 gap-3">
              <button className="bg-[#BCBCBC] rounded-full text-white w-[i43px]  py-2 px-4 flex items-center">
                <img className="mr-1" src={closeIcon} />
                Cancel
              </button>
              <button
                className="bg-[#CD4373] text-white rounded-full  py-2 px-4 flex items-center place-content-center"
                onClick={() =>
                  toast.success("Approval request has been successfully sent.")
                }
              >
                <img className="mr-2" src={sendIcon} />
                Send For Approval
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Quotation;
