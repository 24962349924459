import { ArrowLeft2, ArrowRight2 } from "iconsax-react";
import React from "react";

function Calendar() {
    return (
        <div className="w-full relative bg-white max-h-[100px] h-fit divide-x text-nowrap flex my-3 border rounded-lg *:items-center *:justify-center *:flex *:flex-col overflow-hidden">
            <div className="bg-neutral-500 rounded-l-md flex items-center px-0.5 ">
                <ArrowLeft2 size="20" color="#ffffff" className="" />
            </div>
            <div className="px-4 py-2">
                <p className=" text-nowrap whitespace-nowrap text-neutral-500 text-[13px]">
                    Tue 13 Feb
                </p>
                <p>- -</p>
            </div>
            <div className="px-4 py-2 bg-neutral-200">
                <p className=" text-nowrap whitespace-nowrap text-neutral-500 text-[13px]">
                    Tue 13 Feb
                </p>
                <p className="text-[12px] whitespace-nowrap">
                    KWD <strong>12938</strong>
                </p>
            </div>
            <div className="px-4 py-2">
                <p className=" text-nowrap whitespace-nowrap text-neutral-500 text-[13px]">
                    Tue 13 Feb
                </p>
                <p>- -</p>
            </div>
            <div className="px-4 py-2">
                <p className=" text-nowrap whitespace-nowrap text-neutral-500 text-[13px]">
                    Tue 13 Feb
                </p>
                <p>- -</p>
            </div>
            <div className="px-4 py-2">
                <p className=" text-nowrap whitespace-nowrap text-neutral-500 text-[13px]">
                    Tue 13 Feb
                </p>
                <p>- -</p>
            </div>
            <div className="px-4 py-2">
                <p className=" text-nowrap whitespace-nowrap text-neutral-500 text-[13px]">
                    Tue 13 Feb
                </p>
                <p>- -</p>
            </div>
            <div className="px-4 py-2">
                <p className=" text-nowrap whitespace-nowrap text-neutral-500 text-[13px]">
                    Tue 13 Feb
                </p>
                <p>- -</p>
            </div>
            <div className="px-4 py-2">
                <p className=" text-nowrap whitespace-nowrap text-neutral-500 text-[13px]">
                    Tue 13 Feb
                </p>
                <p>- -</p>
            </div>
            <div className="px-4 py-2">
                <p className=" text-nowrap whitespace-nowrap text-neutral-500 text-[13px]">
                    Tue 13 Feb
                </p>
                <p>- -</p>
            </div>
            <div className="bg-neutral-500 rounded-r-md flex items-center absolute right-0 h-full px-0.5">
                <ArrowRight2 size="20" color="#ffffff" className="" />
            </div>
        </div>
    );
}

export default Calendar;
