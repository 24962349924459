import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

import bgImage from "../../assets/images/backgroundMap.png";
import logoTs from "../../assets/images/logoTs.svg";
import loginIcon from "../../assets/images/loginIcon.svg";

function Login() {
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (username && password) {
      if (username === "agent") {
        navigate("/user");
        return;
      }
      if (username === "admin") {
        navigate("/user/quotation-from-agents");
        return;
      }
      toast.error("Invalid credentials");
    } else {
      toast.error("Please enter credentials");
    }
  };
  return (
    <div
      style={{
        backgroundImage: `url(${bgImage})`,
      }}
      className=" bg-[#FFFCFF] max-w-full bg-center bg-no-repeat h-screen flex justify-center items-center"
    >
      <ToastContainer
        closeOnClick
        style={{ width: "auto", minWidth: "340px", maxWidth: "450px" }}
        position={"top-right"}
        bodyStyle={{ color: "#756f86" }}
      />
      <div className="bg-[#fff] w-[639px] py-[134px] rounded-[10px] shadow-lg px-[66px]">
        <div className="flex flex-col gap-[30px]">
          <div>
            <img src={logoTs} alt="freston travel solution" />
          </div>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <input
                placeholder=" Username"
                type="text"
                id="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className="mt-1 p-[16px] h-[56px] w-full shadow-sm sm:text-sm border border-[#A9A8AA] rounded-md"
                required
              />
            </div>
            <div className="mb-4">
              <input
                placeholder="Password"
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="mt-1 p-[16px] h-[56px] w-full shadow-sm sm:text-sm border border-[#A9A8AA] rounded-md"
                required
              />
            </div>

            <div className="flex justify-between items-end mt-[30px]">
              <div>
                <p className="text-[#C74070] text-[16px] underline">
                  Forgot your password?
                </p>
              </div>
              <div>
                <button className="bg-[#C74070] w-[169px] rounded-[33px] py-[13px] px-[25px] flex gap-[5px] items-center justify-center">
                  <img src={loginIcon} alt="" />
                  <p className="text-[#fff]">Login</p>
                </button>
              </div>
            </div>
          </form>
          <div></div>
        </div>
      </div>
    </div>
  );
}

export default Login;
