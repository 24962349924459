import React from "react";

import { ArrowRight } from "iconsax-react";
import shareImage from "../../assets/images/share-icon.svg";
import { months } from "../../data";

function UserDetails({ data }) {
  return (
    <div className="w-[100%] bg-white my-3 flex  border shadow rounded-lg items-center p-3">
      <div className="w-full max-h-[100px] h-fit flex  divide-x *:px-6 items-center">
        <div className="flex items-center gap-5">
          <div className="flex flex-col">
            <p className="text-xl font-medium">
              {data?.tickets?.[0]?.fromCode || data?.tickets?.fromCode}
            </p>
            <p className="text-sm text-[#333333]">
              {data?.tickets?.[0]?.from || data?.tickets?.from}
            </p>
          </div>
          <div>
            <ArrowRight size="32" color="#333333" />
          </div>
          <div className="flex flex-col">
            <p className="text-xl font-medium">
              {data?.tickets?.[0]?.toCode || data?.tickets?.toCode}
            </p>
            <p className="text-sm text-[#333333]">
              {data?.tickets?.[0]?.to || data?.tickets?.to}
            </p>
          </div>
        </div>

        <div className="flex flex-col ">
          <p className="text-sm ">Departure</p>
          <p className="text-sm text-[#333333]">
            <strong className="text-lg">
              {
                data?.tickets?.[0]?.dateAndTime
                  ?.split(",")?.[0]
                  ?.split("-")?.[2]
              }
              {/* {new Date("2024-02-14, 06:00").getDay()} */}
              {/* {new Date(data?.tickets?.[0]?.dateAndTime).getDay()} */}
              {/* 2024-02-14, 06:00 */}
            </strong>{" "}
            {`${
              months?.[new Date(data?.tickets?.[0]?.dateAndTime)?.getMonth()] ||
              "Feb"
            } '${
              new Date(data?.tickets?.[0]?.dateAndTime)
                ?.getFullYear()
                ?.toString()
                ?.slice(2, 4) || 24
            }`}
          </p>
        </div>
        <div className="flex flex-col ">
          <p className="text-sm font-medium">Travellers</p>
          <p className="text-sm text-[#333333]">
            <strong>{data?.travellers}</strong>
          </p>
        </div>
        <div className="flex flex-col ">
          <p className="text-sm font-medium">Travell Class</p>
          <p className="textlg text-[#333333]">{data?.class}</p>
        </div>
      </div>
      <div className="flex gap-4">
        <div className="max-h-[100px] h-fit border border-[#333333] rounded-lg flex p-3">
          <img className="w-[75px] h-[24px]" src={shareImage} alt="" />
        </div>
        <div className="max-h-[100px] h-fit border border-[#333333] rounded-lg text-nowrap whitespace-nowrap p-3">
          MODIFY SEARCH
        </div>
      </div>
    </div>
  );
}

export default UserDetails;
