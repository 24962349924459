import React from "react";
import logoTs from "../../assets/images/logoTsWhite.svg";
import NotificationIcon from "../../assets/images/notification.svg";
import { Link, useNavigate } from "react-router-dom";
function NavBar() {
  const navigate = useNavigate();
  return (
    <nav className="bg-[#37113E] border-gray-200 ">
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
        <Link to={"/users"}>
          <img src={logoTs} className="h-8" alt="Freston travel solution" />
        </Link>

        <div
          className="hidden w-full md:flex md:w-auto items-center"
          id="navbar-default"
          onClick={() => navigate("ticket-requests")}
        >
          <p className="text-white bg-[#872D2D] rounded-[25px] px-3 py-1 font-medium text-sm">
            Ticket Request
          </p>
          <span className="border-l-[12px] h-0 w-0  border-l-[#872D2D] border-y-[transparent] border-y-[8px] -left-[4px]   relative"></span>
          <img src={NotificationIcon} alt="notification Icon" />
          <span className="text-white text-sm font-medium bg-[#FF5656] rounded-full  ml-3 w-6 h-6 flex items-center justify-center relative -left-6 top-1">
            2
          </span>
        </div>
      </div>
    </nav>
  );
}

export default NavBar;
