import React from "react";
import ArrowIcon from "../../assets/images/arrow.svg";
import homePic1 from "../../assets/images/home-pic-1.png";
import homePic2 from "../../assets/images/home-pic-2.png";
import homePic3 from "../../assets/images/home-pic-3.png";
import homePic4 from "../../assets/images/home-pic-4.png";
import homePic5 from "../../assets/images/home-pic-5.png";
import homePic6 from "../../assets/images/home-pic-6.png";
import homePic7 from "../../assets/images/home-pic-7.png";
import homePic8 from "../../assets/images/home-pic-8.png";

function Home() {
  return (
    <div className="px-[16px] mt-[23px]">
      <div className="flex gap-[44px] w-[60%]">
        <div className="flex gap-[10px] w-full pb-[10px] items-center  border-b-2 border-dashed border-[#585858]">
          <img src={ArrowIcon} alt="" />
          <p className="text-[16px]"> Hawali</p>
        </div>
        <div className="flex gap-[10px] w-full pb-[10px] items-center  border-b-2 border-dashed border-[#585858]">
          <img src={ArrowIcon} alt="" />
          <p className="text-[16px]">Kuwait City</p>
        </div>
        <div className="flex gap-[10px] w-full pb-[10px] items-center  border-b-2 border-dashed border-[#585858]">
          <img src={ArrowIcon} alt="" />
          <p className="text-[16px]">Shuwakh Port</p>
        </div>
      </div>

      <div className="w-full mt-[35px]">
        <p className="text-[20px] pb-[8px] border-b-2 mb-4 border-dashed border-[#585858]">
          Premiun Tour Operator in Kuwait
        </p>
      </div>
      <div className=" grid grid-cols-4 ">
        <div className="p-[30px] flex flex-col gap-[12px] justify-center items-center border-b-2  border-dashed border-[#585858]">
          <img src={homePic1} alt="" />
          <p className="text-[16px] font-semibold">Rajesh Kartha</p>
          <p className="text-[16px] ">Kuwait City</p>
        </div>
        <div className="p-[30px] flex flex-col gap-[12px] justify-center items-center border-b-2 border-l-2 border-dashed border-[#585858]">
          <img src={homePic2} alt="" />
          <p className="text-[16px] font-semibold">Rajesh Kartha</p>
          <p className="text-[16px] ">Kuwait City</p>
        </div>
        <div className="p-[30px] flex flex-col gap-[12px] justify-center items-center border-b-2 border-l-2 border-dashed border-[#585858]">
          <img src={homePic3} alt="" />
          <p className="text-[16px] font-semibold">Rajesh Kartha</p>
          <p className="text-[16px] ">Kuwait City</p>
        </div>
        <div className="p-[30px] flex flex-col gap-[12px] justify-center items-center border-b-2 border-l-2 border-dashed border-[#585858]">
          <img src={homePic4} alt="" />
          <p className="text-[16px] font-semibold">Rajesh Kartha</p>
          <p className="text-[16px] ">Kuwait City</p>
        </div>

        <div className="p-[30px] flex flex-col gap-[12px] justify-center items-center border-b-2  border-dashed border-[#585858]">
          <img src={homePic5} alt="" />
          <p className="text-[16px] font-semibold">Rajesh Kartha</p>
          <p className="text-[16px] ">Kuwait City</p>
        </div>
        <div className="p-[30px] flex flex-col gap-[12px] justify-center items-center border-b-2 border-l-2 border-dashed border-[#585858]">
          <img src={homePic6} alt="" />
          <p className="text-[16px] font-semibold">Rajesh Kartha</p>
          <p className="text-[16px] ">Kuwait City</p>
        </div>
        <div className="p-[30px] flex flex-col gap-[12px] justify-center items-center border-b-2 border-l-2 border-dashed border-[#585858]">
          <img src={homePic7} alt="" />
          <p className="text-[16px] font-semibold">Rajesh Kartha</p>
          <p className="text-[16px] ">Kuwait City</p>
        </div>
        <div className="p-[30px] flex flex-col gap-[12px] justify-center items-center border-b-2 border-l-2 border-dashed border-[#585858]">
          <img src={homePic8} alt="" />
          <p className="text-[16px] font-semibold">Rajesh Kartha</p>
          <p className="text-[16px] ">Kuwait City</p>
        </div>
      </div>
      <div className="w-full mt-[35px] pb-[10px]  mb-4 flex justify-between items-end border-b-2 border-solid border-[#585858]">
        <p className="text-[20px]  ">Premiun Tour Operator in Kuwait</p>
        <button className="bg-[#585858] px-[20px] py-[8px] text-[#ffff] rounded-[33px]">
          view more
        </button>
      </div>

      <div className="mt-[30px]">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[30px]">
          <div className="flex gap-[20px]  py-[15px] md:border-r-2 border-solid  border-[#585858]">
            <div>
              <img
                className="w-[104px] h-full object-cover"
                src={homePic1}
                alt=""
              />
            </div>
            <div className="flex flex-col gap-[20px]">
              <p className="text-[blue] font-semibold text-[16px]">
                Kuwait Tour Package
              </p>
              <p className=" text-[16px]">Kuwait City</p>
              <div className="flex gap-[10px] h-full items-end">
                <button className="bg-[#fff] py-[10px] px-[20px] border border-solid border-red-600 rounded-tl-[33px] rounded-bl-[33px]">
                  View Contact
                </button>
                <button className="bg-red-600 py-[10px] px-[20px] border border-solid border-red-600 text-white rounded-tr-[33px] rounded-br-[33px]">
                  Get Quote
                </button>
              </div>
            </div>
          </div>
          <div className="flex gap-[20px]  py-[15px] md:border-r-2 border-solid  border-[#585858]">
            <div>
              <img
                className="w-[104px] h-full object-cover"
                src={homePic1}
                alt=""
              />
            </div>
            <div className="flex flex-col gap-[20px]">
              <p className="text-[blue] font-semibold text-[16px]">
                Kuwait Tour Package
              </p>
              <p className=" text-[16px]">Kuwait City</p>
              <div className="flex gap-[10px] h-full items-end">
                <button className="bg-[#fff] py-[10px] px-[20px] border border-solid border-red-600 rounded-tl-[33px] rounded-bl-[33px]">
                  View Contact
                </button>
                <button className="bg-red-600 py-[10px] px-[20px] border border-solid border-red-600 text-white rounded-tr-[33px] rounded-br-[33px]">
                  Get Quote
                </button>
              </div>
            </div>
          </div>
          <div className="flex gap-[20px]  py-[15px] ">
            <div>
              <img
                className="w-[104px] h-full object-cover"
                src={homePic1}
                alt=""
              />
            </div>
            <div className="flex flex-col gap-[20px]">
              <p className="text-[blue] font-semibold text-[16px]">
                Kuwait Tour Package
              </p>
              <p className=" text-[16px]">Kuwait City</p>
              <div className="flex gap-[10px] h-full items-end">
                <button className="bg-[#fff] py-[10px] px-[20px] border border-solid border-red-600 rounded-tl-[33px] rounded-bl-[33px]">
                  View Contact
                </button>
                <button className="bg-red-600 py-[10px] px-[20px] border border-solid border-red-600 text-white rounded-tr-[33px] rounded-br-[33px]">
                  Get Quote
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
