import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import App from "./App";
import Home from "./pages/home";
import Login from "./pages/login";
import TicketList from "./pages/ticketsList";
import "./index.css";
import { Ticketrequests } from "./pages/ticketRequests";
import Quotation from "./pages/quotationFromAgents";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/user",
    element: <App />,
    children: [
      { path: "", element: <Home /> },
      { path: "ticket-requests", element: <Ticketrequests /> },
      { path: "ticket-list", element: <TicketList /> },
      { path: "quotation-from-agents", element: <Quotation /> },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
