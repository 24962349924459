import React, { useState } from "react";
import { FaAward } from "react-icons/fa";
import qatarAirwaysPic from "../../assets/images/home-pic-5.png";

export function ListItem({ data }) {
  const [selected, setSelected] = useState(false);
  const [selectedTime, setSelectedTime] = useState(0);
  return (
    <>
      <tr
        className="bg-white shadow-[0px_2px_10px_0px_#0000002E] mb-[2px] pt-4"
        style={{ borderRadius: "5px" }}
        onClick={() => setSelected(!selected)}
      >
        <td className="p-6 relative">
          <div className="absolute top-[25px]">
            <input
              type="checkbox"
              onClick={() => setSelected(!selected)}
              checked={selected}
              className="relative peer appearance-none w-4 h-4 border rounded-sm bg-[#fff] checked:bg-[#ce4372] checked:border-[#ce4372]"
            />
            <svg
              className="absolute w-4 h-4 mt-1 hidden peer-checked:block pointer-events-none top-[-3px]"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="white"
              stroke-width="4"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <polyline points="20 6 9 17 4 12"></polyline>
            </svg>
          </div>
        </td>
        <td className="p-0 pt-4 relative">
          {data?.isBest && (
            <div className="w-[96px] h-[26px] bg-[#25A9F3] text-[#FFFFFF] absolute top-0 text-[12px] font-medium rounded-b-[10px] flex justify-center items-center gap-1">
              <FaAward className="w-[19px] h-[19px]" /> Best Value
            </div>
          )}
          <div className="flex  gap-2">
            <img
              className="w-[30px] h-[30px] rounded-full border"
              src={data?.airlineLogo}
              alt=""
              srcset=""
            />
            <div className="font-medium ">
              <h6 className="">{data?.airline}</h6>
              <p className="text-gray-500 text-xs">{data?.airlineCode}</p>
            </div>
          </div>
        </td>

        <td className="pt-4 ">
          {data?.times?.map((it, ind) => (
            <td className="flex items-center">
              <td className="w-[87px] flex">
                <div className=" mr-[2px] pt-2">
                  <input
                    checked={ind == selectedTime}
                    onChange={() => setSelectedTime(ind)}
                    type="radio"
                    className=""
                    style={{ accentColor: "#ce4372" }}
                  />
                </div>
                <div>
                  <p className="text-2xl font-medium">{it?.from}</p>
                  <p className="text-sm text-[#A2ACAF] mb-2">{data?.from}</p>
                </div>
              </td>

              <td className="text-sm flex flex-col items-center">
                <span className="text-[12px] mb-[-12px]">
                  <strong>{it?.duration?.hr}</strong> Hr.{" "}
                  <strong>{it?.duration?.min}</strong> Min.
                </span>
                <img
                  src="https://media.giphy.com/media/dwEwt4MHTWxoC6gOlp/giphy.gif"
                  alt=""
                  className="w-[150px] h-[35px] object-cover"
                />
              </td>
              <td className="flex flex-1">
                <div className="mr-[20px]">
                  <p className="text-2xl font-medium">{it?.to}</p>
                  <p className="text-sm text-[#A2ACAF] mb-2">{data?.to}</p>
                </div>
              </td>
            </td>
          ))}
        </td>
        <td>
          <img src={data?.agent?.image} className="w-[180px] h-[50px]" />
        </td>
        <td className="pt-4 pr-4">
          <p className="text-lg text-end">
            {`${data?.currency}`}
            <strong className="text-2xl ms-2">{data?.maxPrice}</strong>
          </p>
        </td>
      </tr>
      <div className="mb-2 bg-white" />
    </>
  );
}

export default ListItem;

// function ListItem() {
//     return (
//       <>
//         <div
//           className="flex justify-between bg-white mb-2 p-6"
//           style={{ borderRadius: "5px" }}
//         >
//           <div className="relative w-[40px] grow-1">
//             <input
//               type="checkbox"
//               className="relative peer appearance-none w-4 h-4 border-[1px] border-[#7a8083] rounded-sm bg-[#fff] checked:bg-[#ce4372] checked:border-[#ce4372]"
//             />
//             <svg
//               className="absolute w-4 h-4 mt-1 hidden peer-checked:block pointer-events-none top-[-1px]"
//               xmlns="http://www.w3.org/2000/svg"
//               viewBox="0 0 24 24"
//               fill="none"
//               stroke="white"
//               stroke-width="4"
//               stroke-linecap="round"
//               stroke-linejoin="round"
//             >
//               <polyline points="20 6 9 17 4 12"></polyline>
//             </svg>
//           </div>
//           <div className="flex min-w-fit px-4 flex-col items-center basis-0 pt-[5px]">
//             <div className="flex justify-center items-center">
//               <img
//                 className="w-[30px] h-[30px] border-r-[50%]"
//                 src={emirateIcon}
//                 alt=""
//                 srcset=""
//               />
//               <div>
//                 <p className="text-[13px] text-xs font-normal text-black m-0 mr-0 w-full overflow-hidden whitespace-no-wrap">
//                   Qatar Airways
//                 </p>
//                 <p className="text-[11px] mt-[3px] block text-gray-600 font-bold">
//                   QR-1083
//                 </p>
//               </div>
//             </div>
//           </div>
//           <div className="flex min-w-fit px-4 justify-between basis-0 grow ">
//             <div>
//               <div className="flex">
//                 <div className="pt-[2px] mr-[2px]">
//                   <input
//                     type="radio"
//                     className=""
//                     style={{ accentColor: "#ce4372" }}
//                   />
//                 </div>
//                 <div>
//                   <p className="text-[20px] font-medium text-black">22:05</p>
//                   <p className="capitalize text-xs text-gray-600 block font-normal">
//                     Kuwait
//                   </p>
//                 </div>
//               </div>
//             </div>
//             <div className="flex flex-col items-center">
//               <p className="text-xs font-normal text-black">
//                 <b>1</b> Hr. <b>20</b> Min.
//               </p>
//               <img
//                 src={arrowFlight}
//                 alt=""
//                 srcset=""
//                 className="w-[115px] h-[16px]"
//               />
//             </div>
//             <div>
//               <div className="flex">
//                 <div>
//                   <p className="text-[20px] font-medium text-black">22:05</p>
//                   <p className="capitalize text-xs text-gray-600 block font-normal">
//                     Kuwait
//                   </p>
//                 </div>
//               </div>
//             </div>
//             <div className="flex justify-center items-center">
//               <p className="font-bold text-red-700 uppercase whitespace-no-wrap text-[11px]">
//                 3 Left
//               </p>
//             </div>
//           </div>
//           <div className="flex min-w-fit px-4  flex-col basis-0">
//             <p className="text-xs font-medium text-red-700 line-through">
//               KWD 11998
//             </p>
//             <p className="text-2xl font-bold text-black">KWD 11442</p>
//             <p className="text-xs text-[#2f7d03] font-normal">ATFLY KWD 539</p>
//           </div>
//         </div>
//       </>
//     );
//   }
