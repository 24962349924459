import React, { useState } from "react";
import { ArrowDown2, ArrowRight2 } from "iconsax-react";
import Image1 from "../../assets/images/icon-night.svg";
import Image2 from "../../assets/images/sun-day.png";
import Image3 from "../../assets/images/sun-mornig.svg";
import Image4 from "../../assets/images/sun-set.svg";

const FilterOptionList = () => {
  const [filterData, setFilterData] = useState({
    stops: true,
    fare_type: true,
    departure_time: true,
    arrival_time: true,
    airlines: true,
    price_range: true,
  });
  return (
    <div className="h-[90vh] overflow-y-auto p-3">
      <div
        className="flex justify-start cursor-pointer"
        onClick={() =>
          setFilterData({ ...filterData, stops: !filterData.stops })
        }
      >
        {filterData.stops ? (
          <ArrowDown2 size="20" color="black" variant="Outline" />
        ) : (
          <ArrowRight2 size="20" color="black" variant="Outline" />
        )}

        <div className="text-[14px] font-sans font-bold ">STOPS</div>
      </div>
      {filterData.stops && (
        <div className="flex justify-start mt-1">
          <div>
            <input
              type="text"
              value={0}
              className="w-12 border rounded-md mr-4 text-center"
            />
            <p className="text-xs mr-2">KWD 11442</p>
          </div>
          <div>
            <input
              type="text"
              value={1}
              className="w-12 border rounded-md text-center"
            />
            <p className="text-xs">KWD 13948</p>
          </div>
        </div>
      )}

      <hr className="mt-3" />

      <div
        className="flex justify-start cursor-pointer mt-3"
        onClick={() =>
          setFilterData({
            ...filterData,
            fare_type: !filterData.fare_type,
          })
        }
      >
        {filterData.fare_type ? (
          <ArrowDown2 size="20" color="black" variant="Outline" />
        ) : (
          <ArrowRight2 size="20" color="black" variant="Outline" />
        )}

        <div className="text-[14px] font-sans font-bold ">FARE TYPE</div>
      </div>
      {filterData.fare_type && (
        <div className="flex justify-start mt-1">
          <div className="">
            <input
              type="checkbox"
              id="fare_type"
              className="w-12 rounded-md "
            />

            <label className="mt-1" htmlFor="fare_type">
              Refundable
            </label>
          </div>
        </div>
      )}

      <hr className="mt-3" />

      <div
        className="flex justify-start cursor-pointer mt-3"
        onClick={() =>
          setFilterData({
            ...filterData,
            departure_time: !filterData.departure_time,
          })
        }
      >
        {filterData.departure_time ? (
          <ArrowDown2 size="20" color="black" variant="Outline" />
        ) : (
          <ArrowRight2 size="20" color="black" variant="Outline" />
        )}

        <div className="text-[14px] font-sans font-bold ">DEPARTURE TIMES</div>
      </div>
      {filterData.departure_time && (
        <>
          <p className="ml-2">from Kuwait</p>
          <div className="flex gap-2 mt-1 ml-2 *:px-3">
            <div className="border rounded-md p-1 mr-2 flex flex-col items-center">
              <img src={Image3} alt="icon" className="h-[30px] w-[25px] " />
              <p className="text-xs">05-12</p>
            </div>
            <div className="border rounded-md p-1 mr-2 flex flex-col items-center">
              <img
                src={Image2}
                alt="icon"
                className="h-[23px] my-1 w-[25px] "
              />
              <p className="text-xs">12-18</p>
            </div>
            <div className="border rounded-md p-1 mr-2 flex flex-col items-center">
              <img src={Image4} alt="icon" className="h-[30px] w-[25px] " />
              <p className="text-xs">23-05</p>
            </div>
            <div className="border rounded-md p-1 mr-2 flex flex-col items-center">
              <img src={Image1} alt="icon" className="h-[30px] w-[25px] " />
              <p className="text-xs">18-23</p>
            </div>
          </div>
        </>
      )}

      <hr className="mt-3" />

      <div
        className="flex justify-start cursor-pointer mt-3"
        onClick={() =>
          setFilterData({
            ...filterData,
            arrival_time: !filterData.arrival_time,
          })
        }
      >
        {filterData.arrival_time ? (
          <ArrowDown2 size="20" color="black" variant="Outline" />
        ) : (
          <ArrowRight2 size="20" color="black" variant="Outline" />
        )}

        <div className="text-[14px] font-sans font-bold ">ARRIVAL TIMES</div>
      </div>
      {filterData.arrival_time && (
        <>
          <p className="ml-2">At Doha</p>
          <div className="flex gap-2 mt-1 ml-2 *:px-3">
            <div className="border rounded-md p-1 mr-2 flex flex-col items-center px-2">
              {" "}
              <img src={Image3} alt="icon" className="h-[30px] w-[25px] " />
              <p className="text-xs">05-12</p>
            </div>
            <div className="border rounded-md p-1 mr-2 flex flex-col items-center">
              <img
                src={Image2}
                alt="icon"
                className="h-[23px] my-1 w-[25px] "
              />
              <p className="text-xs">12-18</p>
            </div>
            <div className="border rounded-md p-1 mr-2 flex flex-col items-center">
              <img src={Image4} alt="icon" className="h-[30px] w-[25px] " />
              <p className="text-xs">23-05</p>
            </div>

            <div className="border rounded-md p-1 mr-2 flex flex-col items-center">
              <img src={Image1} alt="icon" className="h-[30px] w-[25px] " />
              <p className="text-xs">18-23</p>
            </div>
          </div>
        </>
      )}

      <hr className="mt-3" />

      <div
        className="flex justify-start cursor-pointer mt-3"
        onClick={() =>
          setFilterData({
            ...filterData,
            airlines: !filterData.airlines,
          })
        }
      >
        {filterData.airlines ? (
          <ArrowDown2 size="20" color="black" variant="Outline" />
        ) : (
          <ArrowRight2 size="20" color="black" variant="Outline" />
        )}

        <div className="text-[14px] font-sans font-bold ">AIRLINES</div>
      </div>
      {filterData.airlines && (
        <>
          <div className="flex justify-between">
            <div className="flex justify-start mt-1">
              <div className="">
                <input
                  type="checkbox"
                  id="fare_type"
                  className="w-12 rounded-md "
                />
                <label className="mt-1" htmlFor="fare_type">
                  Air Arabia(6)
                </label>
              </div>
            </div>

            <div>KWD 16099</div>
          </div>

          <div className="flex justify-between">
            <div className="flex justify-start mt-1">
              <div className="">
                <input
                  type="checkbox"
                  id="fare_type"
                  className="w-12 rounded-md "
                />
                <label className="mt-1" htmlFor="fare_type">
                  Air Arabia(2)
                </label>
              </div>
            </div>

            <div>KWD 37425</div>
          </div>
          <div className="flex justify-between">
            <div className="flex justify-start mt-1">
              <div className="">
                <input
                  type="checkbox"
                  id="fare_type"
                  className="w-12 rounded-md "
                />
                <label className="mt-1" htmlFor="fare_type">
                  Egypt Air(6)
                </label>
              </div>
            </div>

            <div>KWD 85267</div>
          </div>
          <div className="flex justify-between">
            <div className="flex justify-start mt-1">
              <div className="">
                <input
                  type="checkbox"
                  id="fare_type"
                  className="w-12 rounded-md "
                />
                <label className="mt-1" htmlFor="fare_type">
                  Etilhad(7)
                </label>
              </div>
            </div>

            <div>KWD 13948</div>
          </div>
          <div className="flex justify-between">
            <div className="flex justify-start mt-1">
              <div className="">
                <input
                  type="checkbox"
                  id="fare_type"
                  className="w-12 rounded-md "
                />
                <label className="mt-1" htmlFor="fare_type">
                  FLYDUBAI(29)
                </label>
              </div>
            </div>

            <div>KWD 23854</div>
          </div>
        </>
      )}

      <hr className="mt-3" />

      <div
        className="flex justify-start cursor-pointer mt-3"
        onClick={() =>
          setFilterData({
            ...filterData,
            price_range: !filterData.price_range,
          })
        }
      >
        {filterData.price_range ? (
          <ArrowDown2 size="20" color="black" variant="Outline" />
        ) : (
          <ArrowRight2 size="20" color="black" variant="Outline" />
        )}

        <div className="text-[14px] font-sans font-bold ">PRICE RANGE</div>
      </div>
      {filterData.price_range && (
        <div className=" mt-1">
          <input
            id="default-range"
            type="range"
            className="cursor-pointer w-full  rounded-none"
          />

          <div className="flex justify-between text-sm text-slate-500">
            <div>KWD 1142</div>
            <div>KWD 98042</div>
          </div>
        </div>
      )}

      <hr className="mt-3" />

      <div
        className="flex justify-start cursor-pointer mt-3"
        onClick={() =>
          setFilterData({
            ...filterData,
            connecting_airports: !filterData.connecting_airports,
          })
        }
      >
        {filterData.connecting_airports ? (
          <ArrowDown2 size="20" color="black" variant="Outline" />
        ) : (
          <ArrowRight2 size="20" color="black" variant="Outline" />
        )}

        <div className="text-[14px] font-sans font-bold ">
          CONNECTING AIRPORTS
        </div>
      </div>
      {filterData.connecting_airports && (
        <div className=" mt-1">
          <div className="">
            <input
              type="checkbox"
              id="fare_type"
              className="w-12 rounded-md "
            />
            <label className="mt-1" htmlFor="fare_type">
              Abu Dhabi
            </label>
          </div>
          <div className="">
            <input
              type="checkbox"
              id="fare_type"
              className="w-12 rounded-md "
            />
            <label className="mt-1" htmlFor="fare_type">
              Baharain
            </label>
          </div>
          <div className="">
            <input
              type="checkbox"
              id="fare_type"
              className="w-12 rounded-md "
            />
            <label className="mt-1" htmlFor="fare_type">
              Cairo
            </label>
          </div>
          <div className="">
            <input
              type="checkbox"
              id="fare_type"
              className="w-12 rounded-md "
            />
            <label className="mt-1" htmlFor="fare_type">
              Dubai
            </label>
          </div>

          <div className="">
            <input
              type="checkbox"
              id="fare_type"
              className="w-12 rounded-md "
            />
            <label className="mt-1" htmlFor="fare_type">
              Muscat
            </label>
          </div>
        </div>
      )}
    </div>
  );
};

export default FilterOptionList;
